module.exports = [
  {
    title: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-v9'
  },
  {
    title: 'Hybrid',
    style: 'mapbox://styles/navoffroad/clm3rcxi000gh01ra0p7be5fz'
  },
  {
    title: 'Terrain',
    style: 'mapbox://styles/navoffroad/clm3kq9r300gi01rc7e0sbsy1'
  }
];
