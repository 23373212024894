// (c) Stephen Denham, 2024

const osmtogeojson = require('osmtogeojson');
const geojsonNormalize = require('@mapbox/geojson-normalize');
const zoomextent = require('../lib/zoomextent');

async function getTrails(context) {
  document.getElementById('spinner').style.display = 'block';

  const bounds = context.map.getBounds();
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();

  const formBody = `
	[out:json];
	(
	  way["highway"~"^(service|track|unclassified|road|trail)$"]["surface"~"^(dirt|gravel|ground|unpaved|grass|sand|rocky)$"](${sw.lat},${sw.lng},${ne.lat},${ne.lng});
	);
	out body;
	>;
	out skel qt;
	`;

  fetch('https://overpass-api.de/api/interpreter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: formBody
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        document.getElementById('spinner').style.display = 'none';
        alert('Failed to download offroad trails');
        return;
      }
    })
    .then((data) => {
      const osmGj = osmtogeojson(data);
      const gj = geojsonNormalize(osmGj);
      if (gj) {
        colorCodeTrail(gj);
        context.data.mergeFeatures(gj.features);
        document.getElementById('spinner').style.display = 'none';
        zoomextent(context);
        return;
      }
    })
    .catch(() => {
      document.getElementById('spinner').style.display = 'none';
      alert('Failed to download offroad trails');
    });
}

function colorCodeTrail(trail) {
  trail.features.forEach((feature) => {
    feature.properties['stroke-width'] = 3.0;
    if (
      feature.properties.smoothness === 'bad' ||
      feature.properties.tracktype === 'grade2'
    ) {
      feature.properties.stroke = '#00ffff';
    } else if (
      feature.properties.smoothness === 'very_bad' ||
      feature.properties.tracktype === 'grade3'
    ) {
      feature.properties.stroke = '#ffff00';
    } else if (
      feature.properties.smoothness === 'horrible' ||
      feature.properties.tracktype === 'grade4'
    ) {
      feature.properties.stroke = '#ffb0ff';
    } else if (
      feature.properties.smoothness === 'very_horrible' ||
      feature.properties.tracktype === 'grade5'
    ) {
      feature.properties.stroke = '#d765ff';
    } else {
      feature.properties.stroke = '#ffc3d0';
    }
  });
}

module.exports = {
  getTrails
};
