// (c) Stephen Denham, 2023
const mapboxgl = require('mapbox-gl');
let routeCoordinates = [];
let routeDirections = [];
let routeDistance = 0;
let routeMarkers = [];

async function getDirections(context, start, end) {
  async function fetchDirections(profile) {
    const query = await fetch(
      `https://api.mapbox.com/matching/v5/mapbox/${profile}/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&radiuses=50;50&overview=full&steps=false&access_token=${mapboxgl.accessToken}`,
      {
        method: 'GET'
      }
    );
    return await query.json();
  }
  let response = await fetchDirections('driving');
  if (response.code !== 'Ok') {
    response = await fetchDirections('cycling');
    if (response.code !== 'Ok') {
      response = await fetchDirections('walking');
      if (response.code !== 'Ok') {
        routeDistance =
          routeDistance +
          haversineDistance([start[1], start[0]], [end[1], end[0]]);
        routeDirections = routeDirections.concat([
          start.map(Number),
          end.map(Number)
        ]);
        return;
      }
    }
  }
  routeDistance = routeDistance + response.matchings[0].distance;
  routeDirections = routeDirections.concat(
    response.matchings[0].geometry.coordinates
  );
}

function startRoute() {
  const controlButtons = document.getElementsByClassName(
    'mapbox-gl-draw_ctrl-draw-btn'
  );
  for (let i = 0; i < controlButtons.length; i++) {
    controlButtons[i].disabled = true;
  }
}

async function stopRoute(context, isNavigationStyle) {
  if (routeCoordinates.length >= 2) {
    document.getElementById('spinner').style.display = 'block';
    routeDirections = [];
    routeDistance = 0;
    for (let i = 0; i < routeCoordinates.length - 1; i++) {
      const routeStart = routeCoordinates[i].split(',');
      const routeEnd = routeCoordinates[i + 1].split(',');
      await getDirections(context, routeStart, routeEnd);
    }
    const routeGeoJSON = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: routeDirections
          },
          properties: {
            name: localizeDistance(routeDistance),
            stroke: isNavigationStyle ? '#3887be' : 'red',
            'stroke-opacity': isNavigationStyle ? 0.75 : 1.0,
            'stroke-width': isNavigationStyle ? 6.0 : 3.0,
            'line-gap-width': isNavigationStyle ? 2.0 : 0.0
          }
        }
      ]
    };
    if (routeGeoJSON.features[0].geometry.coordinates.length > 0) {
      context.data.mergeFeatures(routeGeoJSON.features);
    }
    document.getElementById('spinner').style.display = 'none';
  }
  clear();
}

function updateRoute(context, event) {
  const coords = event.lngLat.lng + ',' + event.lngLat.lat;
  routeCoordinates.push(coords);

  if (routeCoordinates.length === 1) {
    const routeMarker = new mapboxgl.Marker({ color: '#00f900' })
      .setLngLat([event.lngLat.lng, event.lngLat.lat])
      .addTo(context.map);
    routeMarkers.push(routeMarker);
  } else {
    const routeMarker = new mapboxgl.Marker({ color: '#ff2600' })
      .setLngLat([event.lngLat.lng, event.lngLat.lat])
      .addTo(context.map);
    routeMarkers.push(routeMarker);
  }
  if (routeMarkers.length > 2) {
    for (let i = 1; i < routeMarkers.length - 1; i++) {
      const lngLat = routeMarkers[i].getLngLat();
      routeMarkers[i].remove();
      const routeMarker = new mapboxgl.Marker({ color: '#3887be' })
        .setLngLat(lngLat)
        .addTo(context.map);
      routeMarkers[i] = routeMarker;
    }
  }
}

// Function to calculate the distance in meters between two coordinates
function haversineDistance([lat1, lon1], [lat2, lon2]) {
  const R = 6371000; // Radius of Earth in meters
  const toRadians = (deg) => deg * (Math.PI / 180);

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in meters
}

function localizeDistance(distanceInMeters) {
  const userLocale = navigator.language || 'en-US'; // Detect locale
  const usesMiles =
    userLocale.startsWith('en-US') || userLocale.startsWith('en-GB'); // US and GB use miles

  // Convert distance based on locale (miles for US and GB, kilometers for others)
  let distance, unit;
  if (usesMiles) {
    distance = (distanceInMeters * 0.000621371).toFixed(2); // Convert meters to miles
    unit = 'miles';
  } else {
    distance = (distanceInMeters / 1000).toFixed(2); // Convert meters to kilometers
    unit = 'km';
  }

  // Format number with appropriate locale
  const formattedDistance = new Intl.NumberFormat(userLocale).format(distance);

  return `${formattedDistance} ${unit}`;
}

function clear() {
  const controlButtons = document.getElementsByClassName(
    'mapbox-gl-draw_ctrl-draw-btn'
  );
  for (let i = 0; i < controlButtons.length; i++) {
    controlButtons[i].disabled = false;
  }
  routeCoordinates = [];
  routeMarkers.forEach((routeMarker) => routeMarker.remove());
  routeMarkers = [];
  routeDistance = 0;
  routeDirections = [];
}

function hasRoute() {
  return routeCoordinates.length >= 2 ? true : false;
}

function hasRouteBuilder() {
  const controlButtons = document.getElementsByClassName(
    'mapbox-gl-draw_ctrl-draw-btn'
  );
  for (let i = 0; i < controlButtons.length; i++) {
    if (controlButtons[i].disabled) {
      return true;
    } else {
      return false;
    }
  }
}

module.exports = {
  hasRoute,
  hasRouteBuilder,
  startRoute,
  stopRoute,
  updateRoute,
  clear
};
